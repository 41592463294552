@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");

@media (orientation: landscape) {
    body {
        padding-right: 44px;
        padding-bottom: 21px;
        padding-left: 44px;
    }
}

/* Hide scrollbar for IE, Edge and Firefox */
/* body {
    -ms-overflow-style: none;
} */

.snap-x {
    overflow-x: scroll;
    display: flex;
    gap: 4vw;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scroll-snap-type: x mandatory;
}

.snap-start {
    scroll-snap-align: start;
}

.snap-center {
    scroll-snap-align: center;
}

.store-list:first-child {
    margin-left: 5vw;
}

.store-list:last-child {
    margin-right: 5vw;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.snap-x::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
    display: none;
}

.snap-end {
    scroll-padding-inline-end: 0;
}

select::-ms-expand {
    display: none;
}

/* input  */
input:focus-visible {
    box-shadow: none !important;
}

/* like  */
.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position: absolute;
    top: -9999px;
}
.rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
}
.rate:not(:checked) > label:before {
    content: "★ ";
}
.rate > input:checked ~ label {
    color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

@keyframes bannermove {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
